import React from "react";
import { Button, ButtonColour, ButtonSize } from "../../layout/Button";
import { Separator } from "../../../components/layout/Separator";

interface Props {
    service: ServiceData
}

export type ServiceData = {
    icon: JSX.Element,
    title: string,
    description: string,
    link: string,
}

export const Service: React.FC<Props> = ({ service }) => {
    return (
        <div className="game-dev-service">
            <div className="service-header">
                {service.icon}
                <h3>{service.title}</h3>
            </div>
            <p>{service.description}</p>
            <Button
                buttonConfig={{
                    id: `serviceGradient-${service.title}`,
                    colour: ButtonColour.BLUE,
                    size: ButtonSize.MEDIUM,
                    ctaLabel: "Learn More",
                    ctaLink: service.link,
                    ariaLabel: `Learn more about ${service.title}`,
                }}
            />
            <Separator opacity={0.1} />
        </div>
    )
}