import React from "react";

interface Props {
    fill: string;
}

export const GameProgrammingIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill={fill} className="icon">
            <path d="M8.6,15.9l-7-3.4v2l7,3.3c0.3,0.1,0.6,0.1,0.8,0l7-3.3v-2l-7,3.4C9.2,16,8.8,16,8.6,15.9z" />
            <path d="M1.9,11.7l6.7,3.2c0.3,0.1,0.6,0.1,0.8,0l6.7-3.2c0.4-0.2,0.4-0.7,0-0.9L11,8.3v1.9c0,1.1-0.9,2-2,2
            c-1.1,0-2-0.9-2-2V8.3l-5.2,2.5C1.5,10.9,1.5,11.5,1.9,11.7z"/>
            <path d="M8,4.8v5.4c0,0.6,0.4,1,1,1c0.5,0,1-0.5,1-1V4.8c0.9-0.4,1.5-1.3,1.5-2.3C11.5,1.1,10.4,0,9,0S6.5,1.1,6.5,2.5
		    C6.5,3.6,7.1,4.4,8,4.8z"/>
        </svg>
    )
}